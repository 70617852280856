import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import Buefy from 'buefy'
import VeeValidate from 'vee-validate'
import { Validator } from 'vee-validate'
import dictionary from './dictionary'
import Meta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'

// Route Components
import Homepage from './components/pages/Homepage'
import Venue from './components/pages/Venue'
import Agenda from './components/pages/Agenda'
import Sessions from './components/pages/Sessions'
import Register from './components/pages/Register'
import Tour from './components/pages/Tour'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fab, faEnvelope)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(Buefy)

Vue.use(VueRouter)

Vue.use(VeeValidate)

Vue.use(Meta)

Vue.use(VueScrollTo)

// Register dictionary for validation
Validator.localize(dictionary)

const routes = [
    { path: '/', component: Homepage, name: 'homepage' },
    { path: '/agenda', component: Agenda, name: 'agenda' },
    { path: '/sessions', component: Sessions, name: 'sessions' },
    { path: '/tour', component: Tour, name: 'tour' },
    { path: '/venue', component: Venue, name: 'venue' },
    { path: '/register', component: Register, name: 'register' }
]

new Vue({
    router: new VueRouter({
        mode: 'history',
        // eslint-disable-next-line
        scrollBehavior: function (to, from, savedPosition) {
            if (to.hash) {
                return {selector: to.hash}
            } else {
                return {x: 0, y: 0}
            }
        },
        routes: routes
    }),
    data: {
        days: false,
        venue: false,
        tour: false,
        register: false,
        speakers: false,
        propositions: false,
    },
    render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
