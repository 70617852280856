<template>
    <div class="columns agenda-item">
        <div class="column is-2 agenda-item--time">
            {{ timeParsed }}
        </div>
        <router-link :to="{path: '/sessions', hash: 'session' + id}" class="column agenda-item--block" v-if="speaker">
            <p class="agenda-item--block--title is-size-5">{{ title }}</p>
            <p class="agenda-item--block--name is-size-5">by {{ speaker.fullname }} ({{ speaker.company }}) <span v-if="cospeaker"> and {{ cospeaker.fullname }} ({{ cospeaker.company }})</span></p>
        </router-link>
        <div class="column agenda-item--break" v-else>{{ title }}</div>
    </div>
</template>

<script>
    export default {
        name: "AgendaItem",
        props: ['time', 'title', 'speaker', 'cospeaker', 'id'],
        computed: {
            timeParsed() {
                const time = this.time.split(':')
                return time[0] + ':' + time[1]
            }
        }
    }
</script>

<style lang="scss">
    @import "../../scss/variables";

    .agenda-item {
        margin-top: 1rem;
        margin-bottom: 1rem;
        a {
            &:after {
                content: none;
            }
        }

        &--time {
            color: $black;
            font-size: 1.25rem;
        }
        &--block {
            background-color: $primary-light;
            border-right: 10px solid $primary-dark;
            color: $white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            & > p {
                margin-bottom: 0;
                margin-top: 0;
            }
            &--title {
                font-weight: bold;
            }
            &:hover {
                color: $white;
            }
        }
        &--break {
            font-size: 1.25rem;
        }
    }
</style>