<template>
    <div class="propositions">
        <div class="columns is-multiline">
            <div class="column is-4" v-for="proposition in propositions" :key="proposition.id">
                <value-proposition :title="proposition.title" :image="proposition.icon_path" :description="proposition.description"></value-proposition>
            </div>
        </div>
    </div>
</template>

<script>
    import ValueProposition from '../molecules/ValueProposition'

    export default {
        name: "ValuePropositions",
        components: {ValueProposition},
        computed: {
            propositions() {
                return this.$root.$data.propositions;
            }
        }
    }
</script>

<style lang="scss">
    .propositions {
        margin-top: 3rem;
        & > .columns {
            margin-bottom: 3rem;
        }
    }
</style>
