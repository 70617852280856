<template>
    <div class="columns session-item" :class="{ active: isActive }" :id="'session' + id">
        <div class="column is-4">
            <SessionSpeaker :speaker="speaker"></SessionSpeaker>
            <SessionSpeaker :speaker="cospeaker" v-if="cospeaker"></SessionSpeaker>
        </div>
        <div class="column session-item--content">
            <p>{{ day }} {{ timeParsed }}</p>
            <p><b>{{ title }}</b></p>
            <div v-html="description"></div>
        </div>
    </div>
</template>

<script>
    import SocialButton from "../atoms/SocialButton";
    import SessionSpeaker from "./SessionSpeaker";
    export default {
        name: "SessionItem",
        components: {SessionSpeaker, SocialButton},
        props: ['time', 'day', 'title', 'speaker', 'cospeaker', 'id', 'description'],
        computed: {
            timeParsed() {
                const time = this.time.split(':')
                return time[0] + ':' + time[1]
            },
            isActive() {
                return this.$route.hash === "#session" + this.id;
            }
        }
    }
</script>

<style lang="scss">
    @import "../../scss/variables";

    @keyframes highlight {
        0%   {
            background-color: #649f5900;
        }
        70%  {
            background-color: #649f5952;
        }
        100% {
            background-color: #649f5900;
        }
    }

    .session-item {
        &.active {
            animation: highlight 1s ease-in-out;
        }
        &--image {
            img {
                width: 8rem;
                height: 8rem;
                object-fit: cover;
            }
        }
        &--info {
            & > p {
                margin-bottom: 0.25rem;
            }
        }
        &--content {
            & > p {
                margin-bottom: 0.5rem;
            }
        }
    }
</style>
