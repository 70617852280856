<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
  import axios from "axios";

  import Header from "./components/common/Header";
  import Footer from "./components/common/Footer";

  import metatags from './metatags'

  export default {
      name: 'app',
      metaInfo() {
          return {
              title: this.currentTitle,
              titleTemplate: '%s' + this.titleSuffix,
              meta: [
                  {
                      vmid: 'description',
                      name: 'description',
                      content: this.currentDescription,
                  },
                  {
                      itemprop: 'name',
                      content: this.currentFullTitle
                  },
                  {
                      itemprop: 'description',
                      content: this.currentDescription
                  },
                  {
                      name: 'twitter:title',
                      content: this.currentFullTitle
                  },
                  {
                      name: 'twitter:description',
                      content: this.currentDescription
                  },
                  {
                      name: 'og:title',
                      content: this.currentFullTitle
                  },
                  {
                      name: 'og:description',
                      content: this.currentDescription
                  },
                  {
                      name: 'og:url',
                      content: this.currentUrl
                  },
              ],
          }
      },
      components: {
          Footer,
          Header
      },
      data() {
          return {
              api: process.env.VUE_APP_API_URL,
              metatags: metatags,
              currentRouteName: this.$router.currentRoute.name,
              titleSuffix: " | Sewio Summit",
              currentUrl: process.env.VUE_APP_META_URL + this.$router.currentRoute.path
          }
      },
      watch: {
          '$route' (to) {
              this.currentRouteName = to.name
              this.currentUrl = window.location.origin + to.path
          }
      },
      computed: {
          currentTitle() {
              return this.metatags[this.currentRouteName].title
          },
          currentDescription() {
              return this.metatags[this.currentRouteName].description
          },
          currentFullTitle() {
              return this.currentTitle + this.titleSuffix
          }
      },
      mounted() {
          axios.get(this.api + 'agenda').then((response) => {
              this.$root.$data.days = response.data.days;
          })
          axios.get(this.api + 'venue').then((response) => {
              this.$root.$data.venue = response.data.venue;
          })
          axios.get(this.api + 'register').then((response) => {
            this.$root.$data.register = response.data.register;
          })
          axios.get(this.api + 'tour').then((response) => {
            this.$root.$data.tour = response.data.tour;
          })
          axios.get(this.api + 'speakers').then((response) => {
            this.$root.$data.speakers = response.data.speakers;
          })
          axios.get(this.api + 'propositions').then((response) => {
            this.$root.$data.propositions = response.data.propositions;
          })
      }
  }
</script>

<style lang="scss">
  @import "~bulma/sass/utilities/_all";
  @import "~bulma/sass/base/_all";
  @import "~bulma/sass/elements/_all";
  @import "~bulma/sass/components/_all";
  @import "~bulma/sass/grid/_all";
  @import "~bulma/sass/layout/_all";

  @import "./scss/variables";
  @import "./scss/images";

  @import "./scss/links";

</style>
