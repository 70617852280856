<template>
    <section class="hero">
        <div class="section">
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <h1 class="hero--heading title is-2" v-if="title">
                            {{ title }}
                        </h1>
                        <h2 class="hero--subheading subtitle is-4" v-if="subtitle" v-html="subtitle"></h2>
                    </div>
                    <div class="column is-three-fifths" v-if="!content">
                        <div class="hero--image"></div>
                    </div>
                </div>
                <div class="columns" v-if="content">
                    <div class="column">
                        <p class="hero--description" v-html="content"></p>
                    </div>
                    <div class="column is-three-fifths">
                        <div class="hero--image"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "Hero",
        props: [ 'title', 'subtitle', 'content' ]
    }
</script>

<style lang="scss">
    @import "../../scss/variables";

    .hero {
        > .section {
            padding-bottom: 0;
        }
        color: $white;
        background-color: $primary;
        .title {
            color: $white
        }
        .subtitle {
            color: $orange;
        }
        &--description {
            padding-top: 15px;
        }
        &--image {
            height: 15rem;
            width: 100%;
            background-image: url('../../assets/budvar.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
            @media(max-width: 768px) {
                height: 5rem;
            }
        }
    }
</style>
