<template>
    <div>
        <Hero title="Tour" subtitle="Sewio Summit 2019"></Hero>
        <div class="section">
            <div class="container tour">
                <div class="columns">
                    <div class="column content" v-html="page.content"></div>
                    <div class="column is-4">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Hero from "../common/Hero";
    export default {
        name: "Tour",
        components: {Hero},
        data() {
            return {
                loadingComponent: null,
            }
        },
        computed: {
            loaded() {
                return !!(this.$root.$data.tour)
            },
            page() {
                return (this.loaded) ? this.$root.$data.tour : false
            }
        },
        watch: {
            loaded(newLoaded) {
                if(newLoaded === true && this.loadingComponent !== null) {
                    this.loadingComponent.close()
                }
            }
        },
        mounted() {
            if(this.loaded === false) {
                this.loadingComponent = this.$loading.open({ container: this.$el })
            }
        }
    }
</script>
