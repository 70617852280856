<template>
<div :class="{box: true, 'line-up-speaker': true, 'is-clickable': agendaId !== null}" @click="goToSession(agendaId)">
    <div class="columns">
        <div class="column is-narrow line-up--speaker--image">
            <figure class="image is-128x128 is-fit-cover">
                <img v-if="photo !== null" class="is-rounded" :src="photo" :alt="name">
                <img v-else class="is-rounded" src="../../assets/256x256.png" alt="Placeholder">
            </figure>
        </div>
        <div class="column">
            <div class="columns is-vertical is-gapless">
                <div class="column is-6">
                    <figure class="image is-2by1 is-fit-contain">
                        <img v-if="logo !== null" :src="logo" :alt="name">
                        <img v-else src="../../assets/640x320.png" alt="Placeholder">
                    </figure>
                </div>
                <div class="column line-up--speaker--info">
                    <div class="content">
                        <p>
                            <b>{{ name }}</b><br>
                            {{ position }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "LineUpSpeaker",
        props: {
            photo: {
                type: String
            },
            name: {
                type: String
            },
            logo: {
                type: String
            },
            position: {
                type: String
            },
            agendaId: {
                type: Number,
                default: 0
            }
        },
        methods: {
            goToSession(id = null) {
                if (id === null) {
                    return false
                }
                this.$router.push("/sessions#session" + id)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../scss/variables";

    .content {
        margin-top: 1rem;
    }

    .columns.is-vertical {
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .line-up--speaker {
        transition: box-shadow 0.3s ease-in-out;
        &:hover {
            box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        }
        &--info {
            flex-grow: 0;
        }
        &--image img {
            width: 128px;
            height: 128px;
            filter: grayscale(1) opacity(0.75);
        }
    }
    
    .is-clickable {
        &:hover {
            cursor: pointer;
        }
    }
</style>
