<template>
    <div>
        <Hero title="Agenda" subtitle="Sewio Summit 2019"></Hero>
        <div class="section">
            <div class="container" v-if="loaded">
                <Day v-for="day in days" :key="day.id" :day="day.title" :agendas="day.agendas" ></Day>
            </div>
        </div>
    </div>
</template>

<script>
    import Hero from "../common/Hero";
    import Day from "../organisms/Day";

    export default {
        name: "Agenda",
        components: {Day, Hero},
        data() {
            return {
                loadingComponent: null,
            }
        },
        computed: {
            loaded() {
                return !!(this.$root.$data.days)
            },
            days() {
                return (this.loaded) ? this.$root.$data.days : false
            }
        },
        watch: {
            loaded(newLoaded) {
                if(newLoaded === true && this.loadingComponent !== null) {
                    this.loadingComponent.close()
                }
            }
        },
        mounted() {
            if(this.loaded === false) {
                this.loadingComponent = this.$loading.open({ container: this.$el })
            }
        }
    }
</script>

<style lang="scss">

</style>
