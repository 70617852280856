<template>
    <header>
        <div class="container">
            <nav class="navbar" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <router-link to="/" class="navbar-item">
                        <img src="../../assets/logo.svg" alt="Sewio Summit" class="logo">
                    </router-link>

                    <a role="button" class="navbar-burger" :class="{'is-active': navIsActive}" @click="toggleMenu" aria-label="menu" aria-expanded="false">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div class="navbar-menu" :class="{'is-active': navIsActive}">
                    <div class="navbar-end">
                        <router-link :to="{name: 'homepage'}" class="navbar-item">Home</router-link>
                        <router-link :to="{name: 'agenda'}" class="navbar-item">Agenda</router-link>
                        <router-link :to="{name: 'sessions'}" class="navbar-item">Sessions</router-link>
                        <router-link :to="{name: 'tour'}" class="navbar-item">Tour</router-link>
                        <router-link :to="{name: 'venue'}" class="navbar-item">Venue</router-link>
                        <router-link :to="{name: 'register'}" class="navbar-item">Register</router-link>
                        <a href="https://summit2018.sewio.net/" class="navbar-item" target="_blank">Archive</a>
                    </div>
                </div>
            </nav>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'Header',
        data () {
            return {
                navIsActive: false
            }
        },
        methods: {
            toggleMenu: function () {
                this.navIsActive = !this.navIsActive
            }
        }
    }
</script>

<style lang="scss">
    @import "../../scss/variables";

    header {
        background-color: $primary;
        .navbar {
            min-height: 6rem;
            background-color: $primary;
            .navbar-burger {
                color: $white;
            }
            .navbar-item {
                color: $white;
                text-decoration: none;
                &:hover {
                    background-color: $white;
                    color: $primary;
                }
            }
        }
        .logo {
            width: 200px;
            height: auto;
            min-height: 4rem;
        }
        @media (max-width: 1087px) {
            .navbar-menu {
                background-color: $primary;
            }
        }
    }
</style>
