<template>
    <div>
        <Hero title="Sessions" subtitle="Sewio Summit 2019"></Hero>
        <div class="section">
            <div class="container sessions">
                <template v-if="loaded" v-for="day in days">
                    <div class="columns" v-for="session in day.agendas" :key="session.id" v-if="session.speaker">
                        <div class="column sessions--item">
                            <SessionItem :time="session.time"
                                         :day="day.dayInWeek"
                                         :title="session.title"
                                         :speaker="session.speaker"
                                         :cospeaker="session.co_speaker"
                                         :description="session.description"
                                         :id="session.id"
                            ></SessionItem>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import Hero from "../common/Hero";
    import SessionItem from "../molecules/SessionItem";

    export default {
        name: "Sessions",
        components: {SessionItem, Hero},
        data() {
            return {
                loadingComponent: null,
            }
        },
        computed: {
            loaded() {
                return !!(this.$root.$data.days)
            },
            days() {
                return (this.loaded) ? this.$root.$data.days : false
            }
        },
        watch: {
            loaded(newLoaded) {
                if(newLoaded === true && this.loadingComponent !== null) {
                    this.loadingComponent.close()
                    if(this.$route.hash.length > 0) {
                        this.$nextTick(function () {
                            this.$scrollTo(this.$route.hash)
                        })
                    }
                }
            }
        },
        mounted() {
            if(this.loaded === false) {
                this.loadingComponent = this.$loading.open({ container: this.$el })
            } else {
                if(this.$route.hash.length > 0) {
                    this.$scrollTo(this.$route.hash);
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../scss/variables";

    .container.sessions {
        .sessions--item {
            position: relative;
            margin-top: 3rem;
            margin-bottom: 3rem;
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: $primary-light;
                position: absolute;
                bottom: -2rem;
            }
        }
        > .columns:last-child {
            .sessions--item:after {
                content: none;
            }
        }
    }
</style>
