<template>
<div class="columns is-multiline" v-if="speakers">
    <div class="column is-12">
        <p class="title is-2">Meet the Stars of Sewio Summit 2019</p>
        <br>
    </div>
    <div class="column is-4" v-for="id in selectedSpeakers" :key="id">
        <line-up-speaker :name="speakers[id].fullname"
                         :photo="speakers[id].image"
                         :position="speakers[id].position"
                         :agenda-id="getFirstAgendaItem(id)"
                         :logo="speakers[id].logo_path"></line-up-speaker>
    </div>
</div>
</template>

<script>
    import LineUpSpeaker from "../atoms/LineUpSpeaker";
    export default {
        name: "LineUp",
        components: {LineUpSpeaker},
        data() {
            return {
                selectedSpeakers: [
                    27, 25, 32, 31, 30, 28, 24, 35, 26, 29, 23, 33, 7, 34, 5
                ]
            }
        },
        methods: {
            getFirstAgendaItem(speakerId) {
                if(!this.speakers) {
                    return null;
                }
                if(!(speakerId in this.speakers)) {
                    return null;
                }
                if(this.speakers[speakerId].agendas.length <= 0) {
                    return null;
                }
                return Number(this.speakers[speakerId].agendas[0].id);
            }
        },
        computed: {
            speakers() {
                return this.$root.$data.speakers
            }
        }
    }
</script>

<style scoped>

</style>
